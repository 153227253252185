import React from "react";
import PropTypes from "prop-types";
import FreeFooter from "../components/common/Footer/FreeFooter/FreeFooter";
import HeaderPlaceholder from "../components/common/HeaderPlaceholder/HeaderPlaceholder";
import Head from "../components/Head/Head";
import Header from "../components/Header/Header";
import classConcat from "../util/ClassConcat";


const FundingPage = ({ location }) => {
    const url = "/contact-us/";
    return (
        <>
            <Head />
            <Header pageId={location.pathname} />
            <article className="article">
                <HeaderPlaceholder half />
                <section className={classConcat("container", "section-container")}>
                    <h1 className="section__h1">Payment</h1>
                    <p>
                        <b>Purpose:</b> The goal is to accelerate development of valuable software
                        that could apply to a large audience.
                    </p>
                    <p>
                        <b>Available Funds:</b> OpZoom Fund is a <b>$1 million</b> fund that invests
                        in software technology products. To minimize concentration risk, the maximum
                        investment available to any one company is <b>$100,000</b>.
                    </p>
                    <p>
                        <b>Term & Cost:</b> All investments have a fixed funding term negotiated at the time
                        the investment is made. Funds are available as soon as the product is released
                        and available to real users.
                        Funding is likely to be attached to a software product or a company. The rate of return
                        and rate of success are typically determined based on the market and our
                        investors require a reasonable return on their investment at a portfolio level.&nbsp;
                        <a href={"https://file.opzoom.com/DealRoomStats.pdf"}>LINK</a> to a dealroom.co stats that guide our investors.
                    </p>
                    <p>
                        <b>Qualifications & Requirements:</b> Our investors are more likely to invest
                        in who they see as confident, capable and eager. Their chances of a successful exit
                        depend on your likelihood of raising more investment OR earning revenue quickly. So,
                        relevant evidence of your ability to succeed include past experience in the field,
                        ability to sell or a large engaged following or existence of proven relationships.
                    </p>
                    <p>
                        <b>How to Apply:</b> Your success is important to us. Please&nbsp;
                        <a href={url}>Contact Us</a> and describe the product you need built.
                    </p>
                </section>
            </article>
            <FreeFooter />
        </>
    );
};



FundingPage.propTypes = {
    location: PropTypes.shape({
        key: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
    }).isRequired,
};

export default FundingPage;
